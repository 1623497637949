import { Button } from '@chakra-ui/react';
import { GlobalAiConfig } from '@common/studio-types';
import { Field, Icon, Textarea } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  globalAiConfig?: GlobalAiConfig;
  onGlobalAiConfigChange: (config: GlobalAiConfig) => void;
};

export const GlobalAiConfigurationFields: FC<Props> = ({
  onGlobalAiConfigChange,
  globalAiConfig,
}) => {
  const [textPrompt, setGlobalTextPrompt] = useState<string>('');
  const [imagePrompt, setGlobalImagePrompt] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setGlobalImagePrompt(globalAiConfig?.imagePrompt ?? '');
    setGlobalTextPrompt(globalAiConfig?.textPrompt ?? '');
    setIsSaving(false);
  }, [globalAiConfig]);

  const saveChanges = () => {
    setIsSaving(true);
    onGlobalAiConfigChange({
      imagePrompt,
      textPrompt,
    });
  };

  return (
    <Container>
      <SectionTitle>
        <Icon name="edit-with-ai" size={rawDimensions.size16} />
        <span>Global AI Configuration</span>
      </SectionTitle>
      <Field label="Global Text Prompt">
        <Textarea
          placeholder="Tell AI what to create when generating this node"
          value={textPrompt}
          onChange={(e) => setGlobalTextPrompt(e.target.value)}
        />
      </Field>
      <Field label="Global Image Prompt">
        <Textarea
          placeholder="Tell AI what to create when generating this node"
          value={imagePrompt}
          onChange={(e) => setGlobalImagePrompt(e.target.value)}
        />
      </Field>
      <Button
        variant="primary"
        onClick={saveChanges}
        alignSelf={'flex-end'}
        isLoading={isSaving}
        isDisabled={isSaving}
      >
        Save
      </Button>
    </Container>
  );
};

const Container = styled.div`
  margin: ${dimensions.size16};
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[200]};
  padding-bottom: ${dimensions.size16};
  display: flex;
  flex-flow: column;
`;

const SectionTitle = styled.div`
  ${textStyles.label.lb16b};
  margin-bottom: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  display: flex;
  align-items: center;
  gap: ${dimensions.size8};
  flex-flow: row;
`;
