import { Button, Checkbox } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { GeneratedImageUniqueness } from '@common/studio-types/generateImage';
import { Field, RadioButtons, Textarea } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useGenerateNodeWithAi } from '../../hooks/useGenerateNodeWithAi';
import { Hint } from './../Hint';
import { uniquenessOptions } from './shared';

type Props = {
  studioNodeData: StudioNodeData;
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  onFinishGeneration: () => void;
};

export const GenerateImageNodeWithAi: FC<Props> = (props) => {
  const { studioNodeData, onStudioNodeDataChange, onFinishGeneration } = props;
  const [prompt, setPrompt] = useState('');
  const [generatedImageUrl, setGeneratedImageUrl] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [uniqueness, setUniqueness] =
    useState<GeneratedImageUniqueness>('unique');
  const [isGenerating, setIsGenerating] = useState(false);
  const [useNodeImage, setUseNodeImage] = useState(false);

  const baseImageUrl =
    studioNodeData.type === StudioNodeType.Image
      ? studioNodeData?.imageUrl
      : studioNodeData.type === StudioNodeType.StatBlock
        ? studioNodeData.image?.url
        : undefined;

  const { generateImage } = useGenerateNodeWithAi();

  const onGenerate = async () => {
    setIsGenerating(true);

    const result = await generateImage({
      prompt,
      negativePrompt,
      nodeId: studioNodeData.id,
      uniqueness,
      sourceImageUrl: useNodeImage ? baseImageUrl : undefined,
    })
      .catch(() => void 0)
      .finally(() => setIsGenerating(false));

    setGeneratedImageUrl((result as { imageUrl?: string })?.imageUrl ?? '');
  };

  const onConfirmation = () => {
    if (studioNodeData.type === StudioNodeType.Image) {
      onStudioNodeDataChange({
        ...studioNodeData,
        imageUrl: generatedImageUrl,
        width: 1024,
        height: 1024,
        generateImage: {
          prompt,
          uniqueness,
          negativePrompt: negativePrompt,
        },
      });
    }

    if (studioNodeData.type === StudioNodeType.StatBlock) {
      onStudioNodeDataChange({
        ...studioNodeData,
        image: {
          url: generatedImageUrl,
          width: 1024,
          height: 1024,
        },
        generateImage: {
          prompt,
          uniqueness,
          negativePrompt: negativePrompt,
        },
      });
    }

    onFinishGeneration();
  };

  const close = () => {
    if (!isGenerating) {
      onFinishGeneration();
    }
  };

  return (
    <Container>
      <FormContainer>
        <Hint>
          {generatedImageUrl
            ? 'Confirm the generated image or request a new one.'
            : `Kindly furnish a prompt replete with detailed instructions, so as to
            solicit the creation of an image in accordance with your esteemed
            vision.`}
        </Hint>
        {generatedImageUrl && (
          <ImageContainer>
            <Image
              src={toOptimizedImageUri(generatedImageUrl, {
                dpr: window.devicePixelRatio,
                resize: 'cover',
              })}
              alt="ai generated image"
            />
          </ImageContainer>
        )}
        <Field label="Prompt">
          <Textarea
            placeholder={'What will you imagine?'}
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
          />
        </Field>
        <Field label="Negative Prompt">
          <Textarea
            placeholder={
              'Indicate what the AI should avoid when generating the image.'
            }
            value={negativePrompt}
            onChange={(event) => setNegativePrompt(event.target.value)}
          />
        </Field>
        <Field>
          <Checkbox
            checked={useNodeImage}
            onChange={(e) => setUseNodeImage(e.target.checked)}
          >
            <CheckboxLabel>Use node image to generate new image</CheckboxLabel>
          </Checkbox>
        </Field>
        {useNodeImage && (
          <Field label="Result Uniqueness">
            <Hint>
              Indicate how unique you want the result to be compared to the node
              image.
            </Hint>
            <RadioButtons
              options={uniquenessOptions}
              value={uniqueness}
              onChange={(selection) => setUniqueness(selection)}
            />
          </Field>
        )}
      </FormContainer>

      <FormFooter>
        <Button
          variant="default"
          flex={1}
          onClick={close}
          isDisabled={isGenerating}
        >
          Cancel
        </Button>
        <Button
          flex={1}
          variant="primary"
          isLoading={isGenerating}
          onClick={onGenerate}
          isDisabled={!prompt}
        >
          {generatedImageUrl ? 'Regenerate' : 'Generate'}
        </Button>
      </FormFooter>
      {generatedImageUrl && (
        <ConfirmationContainer>
          <Button
            flex={1}
            variant="primary"
            isLoading={isGenerating}
            onClick={onConfirmation}
          >
            Confirm
          </Button>
        </ConfirmationContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const FormContainer = styled.div`
  flex: 1;
  padding: ${dimensions.size16};
`;

const FormFooter = styled.div`
  display: flex;
  gap: ${dimensions.size16};
  justify-content: stretch;
  width: 100%;
  padding: ${dimensions.size16};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default[100]};
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${dimensions.size16};
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  max-height: 85vh;
  border-radius: ${dimensions.size8};
`;

const ConfirmationContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${dimensions.size0} ${dimensions.size16} ${dimensions.size16};
`;

const CheckboxLabel = styled.div`
  ${textStyles.body.b14m}
`;
